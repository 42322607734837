import React from 'react';
import {Link} from 'gatsby';
import styles from './home.module.scss';
import Typist from 'react-typist'; 

import { 
  ross_outline,
  angela_outline,
  nelson_outline,
  yang_outline } from '../images/team';

import Layout from '../components/layout';

import Slider from "react-slick";

import { berkeley, cornell, stanford, wharton, choate, cate, mit, caltech } from '../images/college/';

const settings = {
      dots: true,
      infinite: false,
      arrows: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      draggable: true,
      pauseOnDotsHover: false,
      pauseOnHover: false,
      swipeToSlide: true,
    };

export default class HomePage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      typing: true,
      counter: 0,
      contentMap: [
        {
          text: "Test Preparation",
          count: 16
        },
        {
          text: "Academic Mentorship",
          count: 19
        },
        {
          text: "AP Enrichment",
          count: 13
        },
        {
          text: "English Language Arts",
          count: 21
        }
      ]
    }
    this.done = this.done.bind(this)
  }

  done() {
    this.setState({
      typing: false,
      counter: this.state.counter === 3 ? 0 : this.state.counter + 1
    }, () => {
      this.setState({typing: true})
    })
  }

  createTextAnimation() {
    if ( this.state.typing ) {
      return (  
        <Typist onTypingDone={this.done} className={styles.typedHeader}>&nbsp;{this.state.contentMap[this.state.counter].text}
          <Typist.Backspace count={this.state.contentMap[this.state.counter].count} delay={1500}></Typist.Backspace>
          
        </Typist>
      )
    } else {
      return ('')
    }
  }
  
  render() {
    let rand = Math.random();
    return(
      <Layout>
        <div className={styles.homePage}>
          <div className={styles.bannerContainer}>
            <div class='ui container'>
              <div className={styles.desktopOnly}>
                <Slider {...settings}>
                  <div>
                    <div className={styles.slideContainer}>
                      <div className={styles.personContentContainer}>
                        <p className={styles.header}>Achieve your best results on AP exams with Ross Gray.</p>
                        <p className={styles.description}>Ross is a test preparation executive who has taught in Shanghai for over 10 years. He is an expert in the SAT, ACT, AP Exams, and SAT II subject exams. Get started with our test preparation program today.</p>
                        <Link to ='/products/ap-enrichment'><button className='btn-outline-blue'>LEARN MORE</button></Link>
                        {/*<Link className={styles.btnOutlineBlue} to='/products/ap-enrichment'>LEARN MORE</Link>*/}
                      </div>
                      <img className={styles.profileImage} src={ross_outline} />
                    </div>
                  </div>
                  <div>
                    <div className={styles.slideContainer}>
                      <div className={styles.personContentContainer}>
                        <p className={styles.header}>Receive admission to your dream schools with Nelson Urena.</p>
                        <p className={styles.description}>Nelson is the former Assistant Director of Admissions at Cornell University. In this role, Nelson read thousands of student applications and helped Cornell build their freshman classes. Nelson helps our students with admissions strategy and long-term profile development. Get started with our admissions counseling program today.</p>
                        {/*<button className='btn-outline-blue'>LEARN MORE</button>*/}
                        <Link to ='/products/college-counseling'><button className='btn-outline-blue'>LEARN MORE</button></Link>
                      </div>
                      <img className={styles.profileImage} src={nelson_outline} />
                    </div>
                  </div>
                  <div>
                    <div className={styles.slideContainer}>
                      <div className={styles.personContentContainer}>
                        <p className={styles.header}>Receive your perfect score on the SAT or ACT with Yang Li</p>
                        <p className={styles.description}>Yang received a perfect score on his SAT in high school. Since moving to Shanghai five years ago, he has helped dozens of students receive scores in the 99th percentile on both the SAT and ACT. Get started with our standardized test preparation program today.</p>
                        <Link to={rand > 0.5 ? '/products/sat-prep' : '/products/act-prep'}><button className='btn-outline-blue'>LEARN MORE</button></Link>
                        {/*<button className='btn-outline-blue'>LEARN MORE</button>*/}
                      </div>
                      <img className={styles.profileImage} src={yang_outline} />
                    </div>
                  </div>
                  <div>
                    <div className={styles.slideContainer}>
                      <div className={styles.personContentContainer}>
                        <p className={styles.header}>Tell your most compelling story with Angela Kong</p>
                        <p className={styles.description}>Angela is a published poet, writer and interview expert. She helps our students master the art of personal narrative essay writing and storytelling in the college application process. Get started with our admissions counseling program today.</p>
                        {/*<button className='btn-outline-blue'>LEARN MORE</button>*/}
                        <Link to ='/products/english-language-arts'><button className='btn-outline-blue'>LEARN MORE</button></Link>
                      </div>
                      <img className={styles.profileImage} src={angela_outline} />
                    </div>
                  </div>
                </Slider>
              </div>
              <div className={styles.mobileOnly}>
                <div>
                  <div className={styles.typedHeader}>&nbsp;Holistic Education for</div>
                  {this.createTextAnimation()}
                  <p className={[styles.description, styles.white].join(' ')}>No matter where you are in the college or high school application process, our world-class team of experts can help you on your way.</p>
                  <Link to='/consulting/main'><div className={styles.button}>Mentorship Programs</div></Link>
                  <Link to='/test-prep/main'><div className={styles.button}>Test Prep Programs</div></Link>
                </div>
              </div>
            </div>   
          </div>
        <div className={[styles.resultsSection, styles.section].join(' ')}>
          <div className={styles.header}>Our Results</div>
          <div className={styles.description}>We think our students' performance is the greatest indicator of our success. Below are just a few of the schools that our students have successfully applied to over the years.</div>

          <div className={styles.wrapper}>
            <div><img src={berkeley} /></div>
            <div><img src={stanford} /></div>
            <div><img src={cornell} /></div>
            <div><img src={wharton} /></div>
            <div><img src={choate} /></div>
            <div><img src={mit} /></div>
            <div><img src={cate} /></div>
            <div><img src={caltech} /></div>
          </div>
          </div>  
          <div className={styles.servicesSection}>
            <p className={styles.header}>Our Services</p>
            <p className={styles.description}>Whether you're looking for test preparation, a mentor for the college application process, or training in general academic skills, we have a service tailored to the needs of your student. As always, feel free to contact us anytime to learn more or discuss your needs.</p>
            
            <div className={styles.serviceWrapper}>
              <div className={styles.serviceColumn}>
                <div className={styles.columnHeader}>
                  <span className={[styles.dot, styles.clear].join(' ')}></span>
                  <span className={[styles.dot, styles.clear].join(' ')}></span>
                  <span className={styles.gradeHeader}><span style={{fontSize: '11px'}}>PRIMARY</span><span style={{fontSize: '11px'}}>SCHOOL</span></span>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                </div>
                <div><Link className={styles.description} to='/products/english-language-arts'>Elementary School ELA</Link></div>
                <div><Link className={styles.description} to='/products/high-school-counseling'>Junior High School Counseling</Link></div>
                <div><Link className={styles.description} to='/products/ssat-prep'>SSAT Preparation</Link></div>
                <div><Link className={styles.description} to='/products/english-language-arts'>Creative Writing</Link></div>
              </div>
              <div className={styles.serviceColumn}>
                <div className={styles.columnHeader}>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                  <span className={styles.gradeHeader}><span style={{fontSize: '12px'}}>MIDDLE</span><span style={{fontSize: '11px'}}>SCHOOL</span></span>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                </div>
                <div><Link className={styles.description} to='/products/english-language-arts'>Middle School ELA</Link></div>
                <div><Link className={styles.description} to='/products/high-school-counseling'>High School Counseling</Link></div>
                <div><Link className={styles.description} to='/products/ssat-prep'>SSAT Preparation</Link></div>
              </div>
              <div className={styles.serviceColumn}>
                <div className={styles.columnHeader}>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                  <span className={styles.gradeHeader}><span style={{fontSize: '22px'}}>9th</span><span>GRADE</span></span>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                </div>
                <div><Link className={styles.description} to='/products/english-language-arts'>Middle School ELA</Link></div>
                <div><Link className={styles.description} to='/products/high-school-counseling'>High School Counseling</Link></div>
                <div><Link className={styles.description} to='/products/ssat-prep'>SSAT Preparation</Link></div>
                <div><Link className={styles.description} to='/products/academic-mentorship'>Academic Mentorship</Link></div>
              </div>
              <div className={styles.serviceColumn}>
                <div className={styles.columnHeader}>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                  <span className={styles.gradeHeader}><span style={{fontSize: '22px'}}>10th</span><span>GRADE</span></span>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                </div>
                <div><Link className={styles.description} to='/products/english-language-arts'>High School ELA</Link></div>
                <div><Link className={styles.description} to='/products/sat-prep'>SAT Preparation</Link></div>
                <div><Link className={styles.description} to='/products/act-prep'>ACT Preparation</Link></div>
                <div><Link className={styles.description} to='/products/sat-2-prep'>SAT II Preparation</Link></div>
                <div><Link className={styles.description} to='/products/ap-enrichment'>AP Enrichment</Link></div>
                <div><Link className={styles.description} to='/products/academic-mentorship'>Academic Mentorship</Link></div>
              </div>
              <div className={styles.serviceColumn}>
                <div className={styles.columnHeader}>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                  <span className={styles.gradeHeader}><span style={{fontSize: '22px'}}>11th</span><span>GRADE</span></span>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                </div>
                <div><Link className={styles.description} to='/products/sat-prep'>SAT Preparation</Link></div>
                <div><Link className={styles.description} to='/products/act-prep'>ACT Preparation</Link></div>
                <div><Link className={styles.description} to='/products/sat-2-prep'>SAT II Preparation</Link></div>
                <div><Link className={styles.description} to='/products/ap-enrichment'>AP Enrichment</Link></div>
                <div><Link className={styles.description} to='/products/academic-mentorship'>Academic Mentorship</Link></div>
              </div>
              <div className={styles.serviceColumn}>
                <div className={styles.columnHeader}>
                  <span className={styles.dot}></span>
                  <span className={styles.dot}></span>
                  <span className={styles.gradeHeader}><span style={{fontSize: '22px'}}>12th</span><span>GRADE</span></span>
                  <span className={[styles.dot, styles.clear].join(' ')}></span>
                  <span className={[styles.dot, styles.clear].join(' ')}></span>
                </div>
                <div><Link className={styles.description} to='/products/ap-enrichment'>AP Enrichment</Link></div>
                <div><Link className={styles.description} to='/products/college-counseling'>College Counseling</Link></div>
              </div>
            </div>
          </div>  
        </div> 
      </Layout>
    )
  }
}
